import React from 'react'
import Layout from '../components/Layout'
import useSiteMetadata from "../components/SiteMetadata";
import {Helmet} from "react-helmet";

const NotFoundPage = () => {
    const {title: siteTitle} = useSiteMetadata();

    return <Layout>
        <Helmet>
            <title>{siteTitle} - 404 Not Found</title>
        </Helmet>
        <div className="osi-contents">
            <div className="content">
                <h1 className="title is-1">NOT FOUND</h1>
                <p>URLのページが、どこを探しても見つかりませんでした。</p>
            </div>
            <div className="content">
                <p className="is-size-4 is-italic">「そこに無ければ無いですね」</p>
                <p className="is-italic has-text-right">――――WEBサーバー接客マニュアル第404条</p>
            </div>
        </div>
    </Layout>
}

export default NotFoundPage
